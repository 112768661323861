import { PERMISSIONS } from './../../../services/permissions/permissions.service';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import {
  ChallengeStatusType,
  InvoiceAnswer,
  INVOICES_ANSWER_STATUSES,
  INVOICES_STATUS_OPTIONS
} from '../../../models/answers/answers-models';
import {InvoicesService} from '../invoices.service';

@Component({
  selector: 'app-invoices-edit',
  templateUrl: './invoices-edit.component.html',
  styleUrls: ['./invoices-edit.component.scss']
})

export class InvoicesEditComponent implements OnInit {

  @Output() onCancel = new EventEmitter<void>();
  loading = false;
  invoiceAnswer: InvoiceAnswer;
  newStatus: ChallengeStatusType;
  statuses = INVOICES_ANSWER_STATUSES;
  statusOptions = INVOICES_STATUS_OPTIONS;
  displayedColumns: string[] = ['name', 'quantity', 'value'];

  invoiceDuplicated = false;
  idConfirmation;
  totalConfirmation;
  productsConfirmation = [];
  rejectionCause;
  comment;
  dateConfirmation;
  rejectionCauses = [
    { label:'  Aprobado'},
    { label:'Rechazado'},
    { label:'Recuerda ingresar la fecha tal cual como registre en la factura'},
    { label:'Los datos registrados no corresponden a la factura'},
    { label:'No ingresa decimales'},
    { label:'Factura borrosa'},
    { label:'Factura cortada'},
    { label:'Factura duplicada'},
    { label:'La factura no cargó correctamente en el sistema'},
    { label:'La imagen no corresponde a una factura'},
    { label:'Prueba '},
    { label:'La factura no contiene productos que participen en el programa'},
    { label:'Factura ingresada por otro usuario '},
    { label:'La factura no cumple con la mecánica establecida'},
    { label:'Recuerda no restar los descuentos de la factura'},
    { label:'Recuerda solo ingresar la suma de los productos  que participen en el programa'},
    { label:'Debes ingresar el subtotal de la factura sin IVA y sin descuento'},
    { label:'Ingresaste un valor mayor'},
    { label:'ingresaste un valor menor'},
    { label:'Ingresa el consecutivo tal como registraste en la factura.'},
    { label:'Recuerda ingresar la suma de los productos con decimales'},
    { label:'Factura no válida-Factura fuera de fecha'},
    { label:'Factura manual'},
    { label:'En la factura no se evidencia la fecha'},
  ];
  id;
  PERMISSIONS = PERMISSIONS;
  constructor(
    @Inject('InvoicesFirestore') private challengesFire: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private invoicesService: InvoicesService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.getChallengeById(params.id);
      this.id = params.id;
    });
  }

  async getChallengeById(id: string) {
    this.loading = true;
    const db = this.challengesFire.firestore;
    const docSnap = await db.doc(`InvoicesAnswers/${id}`).get();
    this.loading = false;
    if (!docSnap.exists) {
      console.error(`Invoice Answer '${id}' not found.`);
      this.invoiceAnswer = null;
      return;
    }
    this.invoiceAnswer = docSnap.data() as InvoiceAnswer;

    console.log(this.invoiceAnswer);
    this.invoiceAnswer.id = id;
    this.newStatus = this.invoiceAnswer.status;
    if (this.invoiceAnswer) {
      if(!this.invoiceAnswer.photos)  {
        this.invoiceAnswer.photos = [];
        if (this.invoiceAnswer.photo) {
          this.invoiceAnswer.photos.push({type:'camera', value:this.invoiceAnswer.photo});
        }
        if (this.invoiceAnswer.image) {
          this.invoiceAnswer.photos.push({type:'file', value:this.invoiceAnswer.image});
        }
      }
      if (!this.invoiceAnswer.products){
        this.invoiceAnswer.products = [];
      }
      this.productsConfirmation = this.invoiceAnswer.products? this.invoiceAnswer.products : [];
      this.rejectionCause =  this.invoiceAnswer.rejectionCause ?  this.invoiceAnswer.rejectionCause : '';
      this.comment = this.invoiceAnswer.comment ? this.invoiceAnswer.comment : '';
      this.totalConfirmation = this.invoiceAnswer.invoiceConfirmation ? this.invoiceAnswer.invoiceConfirmation.totalConfirmation : '';
      this.dateConfirmation = this.invoiceAnswer.invoiceConfirmation ? this.invoiceAnswer.invoiceConfirmation.dateConfirmation : '';
      this.idConfirmation = this.invoiceAnswer.invoiceConfirmation ? this.invoiceAnswer.invoiceConfirmation.invoiceIdConfirmation : '';
    }

  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/invoices');
  }

  changeDataProducts(index,type,value){
    if(!this.invoiceAnswer.products) {
      this.invoiceAnswer.products = [];
    }
    this.invoiceAnswer.products[index][type] = value.target.value;
  }

  okClicked() {
    /*  if (this.newStatus === this.invoiceAnswer.status) {
      this.router.navigateByUrl('/pages/invoices');
      return;
    } */
    const dataConfirmation = {
      id: this.idConfirmation,
      dateConfirmation: this.dateConfirmation,
      totalConfirmation: this.totalConfirmation,
      products: this.invoiceAnswer.products,
      rejectionCause: this.rejectionCause,
      comment: this.comment,
      statusHistory: this.invoiceAnswer.statusHistory
    };

    console.log(dataConfirmation);
    if(this.newStatus !== 'in-progress' ){
      if((!dataConfirmation.id || !dataConfirmation.dateConfirmation || !dataConfirmation.totalConfirmation) ){
        Swal.fire('','Ingresa los campos de confirmación', 'error');
        return;
      }
      if ((this.newStatus === 'rejected' )) {
        if(!dataConfirmation.rejectionCause){
          Swal.fire('','Ingresa el campo Causa Raíz para rechazadas', 'error');
          return;
        }
      }
    }
    this.invoicesService.updateInvoicesStatus(this.invoiceAnswer.id, this.newStatus, dataConfirmation)
      .then(
        () => this.router.navigateByUrl('/pages/invoices'),
        err => Swal.fire('Cambiando Estado', err.message || err, 'error')
      );
  }

  addProduct(){
    this.invoiceAnswer.products.unshift({name:'',quantity:'',value:''});
  }
  removeProduct(index){
    Swal.fire({
      title: `Deseas eliminar este producto?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'CANCELAR'
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      ( this.invoiceAnswer.products as [] ).splice(index,1);
    });
  }

  verifyInvoiceId() {
    this.invoiceDuplicated = false;
    if (typeof (this.invoiceAnswer.invoiceConfirmation) !== 'undefined') {
      if (this.idConfirmation != this.invoiceAnswer.invoiceConfirmation.invoiceIdConfirmation) {
        this.invoicesService.verifyId(this.idConfirmation)
          .then((data) => {
            console.log(data.empty);

            this.invoiceDuplicated = !data.empty;
          });
      }
    } else {
      this.invoicesService.verifyId(this.idConfirmation)
        .then((data) => {
          console.log(data.empty);

          this.invoiceDuplicated = !data.empty;
        });
    }

  }
}
