import { PERMISSIONS } from "./../../../services/permissions/permissions.service";
import { Injectable, Inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore, DocumentData } from "@angular/fire/firestore";
import { FirebaseFirestore, FirebaseStorage } from "@angular/fire";
import { AuthService } from "../../../services/auth.service";
import { Papa } from "ngx-papaparse";

const PERMISSIONS_TYPE_IMPORTER = {
  users_by_appid: PERMISSIONS.IMPORTER_USERS_BY_APPID,
  users: PERMISSIONS.IMPORTER_USERS_BY_APPID,
  bank_transactions: PERMISSIONS.IMPORTER_BANK_TRANSACTIONS,
  transaction_credits: PERMISSIONS.IMPORTER_BANK_TRANSACTIONS,
  // , challenge_replicator : PERMISSIONS
  kpis: PERMISSIONS,
  central_deliveries_updater: PERMISSIONS,
  central_preinvoices_updater: PERMISSIONS,
  bonos_orders_admin: PERMISSIONS.IMPORTER_ORDERS_BY_ADMIN,
};
export interface ImportsFilter {
  appId?: string;
  uid?: string;
  type?: string;
  date?: {
    start: Date;
    end: Date;
  };
  status?: string;
}

@Injectable({
  providedIn: "root",
})
export class ImportService {
  store: FirebaseStorage;
  db: FirebaseFirestore;
  lastVisible: any;

  constructor(
    @Inject("DataImportersFireAuth") private importersAuth: AngularFireAuth,
    @Inject("DataImportersFirestore")
    private importersFirestore: AngularFirestore,
    @Inject("DataImportersFireStorage")
    private importersStorage: AngularFireStorage,
    private authService: AuthService,
    private papa: Papa
  ) {}

  async importFile(file: File, ref: string) {
    return await this.store.ref(ref).put(file);
  }

  async createImportation(data: any) {
    return await this.db.collection("importers").add(data);
  }

  async readAllImports(
    limit: number,
    filter: ImportsFilter,
    allowedAppIds?: string[]
  ) {
    try {
      const dataa = await this.getDataFilter(filter, limit, allowedAppIds);
      if (!dataa) {
        return;
      }
      const dataWithoutAppId = (
        await this.getDataFilter(filter, limit, allowedAppIds, false)
      ).docs;
      // const setData  = new Set([...dataa.docs,...dataWithoutAppId]);
      const docsProms = [...dataa.docs, ...dataWithoutAppId].map(
        async (item) => {
          // const docsProms = [...dataa.docs].map(async (item) => {
          const data = item.data();

          data.idImport = item.id;

          data.state = this.getStatus(data.state);

          if (data.errorFileStart) {
            const errorFileStartLines = data.errorFileStart.split("\n");
            if (errorFileStartLines.length > 1) {
              // Tomar último campo CSV de las líneas 2 a 6
              const parseResult = this.papa.parse(data.errorFileStart, {
                header: false,
              });
              data.errorFileStartLines = parseResult.data
                .slice(1, 6)
                .map((r: string[]) => r.pop().substring(0, 100));
            }
          }

          try {
            if (data.fileHasError) {
              data.errors_url = await this.getErrorsUrl(data);
            }
          } catch (err) {
            console.error(err);
            data.errors_url = null;
          }

          try {
            data.file = await this.store.refFromURL(data.file).getDownloadURL();
          } catch (err) {
            console.log(err);
            data.file = null;
          }

          return data;
        }
      );
      const docs = await Promise.all(docsProms);
      const uniqueArray = Array.from(
        new Map(docs.map((item) => [item.idImport, item])).values()
      );
      return uniqueArray;
    } catch (error) {
      console.error("readAllImports", error);
    }
  }

  async getErrorsUrl(data: DocumentData) {
    const joinFile = this.store.ref(`${data.errors_prefix}-join.csv`);
    let joinFileUrl: string | null = null;
    try {
      joinFileUrl = await joinFile.getDownloadURL();
    } catch (err) {
      if (err.code !== 'storage/object-not-found') {
        console.error(err);
      }
      joinFileUrl = null;
    }
    if (joinFileUrl) {
      return joinFileUrl;
    }
    const files = await this.store
    .ref(`import-errors/${data.idImport}`)
    .listAll();
    if (files.items && files.items.length) {
      return await files.items[0].getDownloadURL();
    }
    return null;
  }

  async configFirebase() {
    await this.authService.getRemoteFirebaseUser(
      this.importersAuth,
      "dataimporters"
    );
    this.store = this.importersStorage.storage;
    this.db = this.importersFirestore.firestore;
  }

  private getStatus(status: string): string {
    return status.split("_").slice(2).join("_");
  }

  private async getDataFilter(
    filter: ImportsFilter,
    limit: number,
    allowedAppIds?: string[],
    hasAppId = true
  ) {
    try {
      let data = this.db
        .collection("importers")
        .orderBy("date_time", "desc")
        .limit(limit);

      if (allowedAppIds?.length && !filter.appId) {
        if (hasAppId) {
          data = data.where("appId", "in", allowedAppIds);
        } else {
          data = data.where("appId", "==", null);
        }
      }
      {
      }
      if (filter) {
        if (filter.appId) {
          data = data.where("appId", "==", filter.appId);
        }

        if (filter.uid) {
          data = data.where("uid", "==", filter.uid);
        }

        if (filter.type) {
          data = data.where("type", "==", filter.type);
        }

        if (filter.status) {
          data = data.where("state", "==", filter.status);
        }

        if (filter.date && filter.date.start && filter.date.end) {
          data = data.where("date_time", ">=", filter.date.start);
          data = data.where("date_time", "<=", filter.date.end);
        }
      }

      return await data.get();
    } catch (error) {
      console.error("getDataFilter", error);
    }
  }
}
