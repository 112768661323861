import { StorageService } from './../storage.service';
import { Injectable } from '@angular/core';

export const KEY_PERMISSIONS_USER = 'PermissionsUser';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
private permissions: string[] = [];


  constructor(private storageService:StorageService ) {}

  async isSuperAdmin():Promise<boolean>{
      const AccountRoles: string[] = (await this.storageService.get('account')).roles;
      return AccountRoles.includes('super_admin');
  }

  async getPermissions() {
    const permissions = this.permissions?.length ? this.permissions : await this.getPermissionStorage();
    return permissions;
  }
   async getPermissionStorage():Promise<any[]> {
    const permission = await this.storageService.get(KEY_PERMISSIONS_USER);
    this.setPermissions = permission;
    return permission
  }
  set setPermissions(permissions: any[]) {
    this.permissions = permissions;
  }
  async hasPermission(permission: string) {
    if(await (this.isSuperAdmin)) return true;
    const permissions = await this.getPermissions()
    return permissions.includes(permission);
  }
  async hasAnyPermission(permission: string[]) {
    if (!permission?.length) {
      return false;
    }
    const permissions = await this.getPermissions();
    return permission.some(p => permissions.includes(p));
  }



}

export const PERMISSIONS = {
  ACCESS_ALL: 'users.apps.access_all',
  // companies
  CREATE_COMPANY: 'companies.create',
  VIEW_COMPANIES: 'companies.view',
  UPDATE_COMPANY: 'companies.update',
  // programs
  CREATE_PROGRAM: 'programs.create',
  VIEW_PROGRAMS: 'programs.view',
  UPDATE_PROGRAM: 'programs.update',
  // users
  VIEW_USERS: 'users.app_users.view',
  CREATE_USERS: 'users.app_users.create',
  UPDATE_USERS: 'users.app_users.update',
  //apps
  UPDATE_APPS: 'users.apps.update',
  VIEW_APPS: 'users.apps.view',
  CREATE_APPS: 'users.apps.create',

  //Service account 
  UPDATE_SERVICE_ACCOUNT : 'service_accounts.view',
  VIEW_SERVICE_ACCOUNT : 'service_accounts.create',
  CREATE_SERVICE_ACCOUNT : 'service_accounts.update',
  // bank
  CREATE_ACCOUNT: 'bank.account.create',
  VIEW_ACCOUNT: 'bank.account.view',
  UPDATE_ACCOUNT: 'bank.account.update',
  CREATE_TRANSACTION: 'bank.transaction.create',
  VIEW_TRANSACTION: 'bank.transaction.view',
  UPDATE_TRANSACTION: 'bank.transaction.update',
  // account type
  CREATE_ACCOUNT_TYPE: 'bank.accountType.create',
  VIEW_ACCOUNT_TYPE: 'bank.accountType.view',
  UPDATE_ACCOUNT_TYPE: 'bank.accountType.update',
  // kpis
  CREATE_KPI: 'kpis.AdminKpis.create',
  VIEW_KPI: 'kpis.AdminKpis.view',
  UPDATE_KPI: 'kpis.AdminKpis.update',
  CREATE_CATEGORIES_KPI : 'kpis.KpiCategories.create',
  VIEW_CATEGORIES_KPI : 'kpis.KpiCategories.view',
  UPDATE_CATEGORIES_KPI : 'kpis.KpiCategories.update',
  CREATE_SECTIONS_KPI : 'kpis.KpiSections.create',
  VIEW_SECTIONS_KPI : 'kpis.KpiSections.view',
  UPDATE_SECTIONS_KPI : 'kpis.KpiSections.update',
  
  // importers
  IMPORTER_USERS_BY_APPID: 'importers.users_by_appid',
  IMPORTER_BANK_TRANSACTIONS: 'importers.bank_transactions',
  IMPORTER_KPI: 'importers.kpis',
  IMPORTER_CENTRAL_DELIVERIES_UPDATER: 'importers.central_deliveries_updater',
  IMPORTER_CENTRAL_PREINVOICES_UPDATER: 'importers.central_preinvoices_updater',
  IMPORTER_ORDERS_BY_ADMIN: 'importers.orders_by_admin',
  VIEW_CMS:'cms_content.view',
  VIEW_OLD_CMS : 'cms_content_old.view',

  //exhibitions
  CREATE_EXHIBITION_TABLE: 'exhibitions.tables.create',
  UPDATE_EXHIBITION_TABLE: 'exhibitions.tables.update',
  VIEW_EXHIBITION_TABLE: 'exhibitions.tables.view',
  VIEW_EXHIBITION_FORM : 'exhibitions.forms.view',
  CREATE_EXHIBITION_FORM : 'exhibitions.forms.create',
  UPDATE_EXHIBITION_FORM : 'exhibitions.forms.update',


  //points 
  POINTS_BRAND_CREATE:'brand.create',
  POINTS_BRAND_VIEW:'brand.view',
  POINTS_BRAND_UPDATE:'brand.update',
  POINTS_COMMERCE_CREATE:'commerce.create',
  POINTS_COMMERCE_VIEW:'commerce.view',
  POINTS_COMMERCE_UPDATE:'commerce.update',
  POINTS_SALES_LOCATION_CREATE:'salesLocation.create',
  POINTS_SALES_LOCATION_VIEW:'salesLocation.view',
  POINTS_SALES_LOCATION_UPDATE:'salesLocation.update',
  POINTS_CATEGORY_VIEW:'category.view',
  POINTS_CATEGORY_CREATE:'category.create',
  POINTS_CATEGORY_UPDATE:'category.update',
  POINTS_EXCHANGES_VIEW : 'pos.exchange.view',
  POINTS_EXCHANGES_CREATE : 'pos.exchange.create',
  POINTS_BALANCE_FIND : 'pos.balance.find',
  POINTS_BALANCE_VIEW : 'post.balance.view',
  POINTS_EXCHANGES_UPDATE : 'pos.exchange.update',

  //answer
  ANSWER_INVOICE_VIEW : 'invoice_answers.view',
  ANSWER_INVOICE_UPDATE : 'invoice_answers.update',
  ANSWER_EXHIBITION_VIEW : 'exhibitions_answers.view',
  ANSWER_EXHIBITION_UPDATE : 'exhibitions_answers.update',
  ANSWER_CHALLENGE_VIEW : 'challenges_answers.view',
  ANSWER_CHALLENGE_UPDATE : 'challenges_answers.update',
  // REPORTES
  REPORT_INVOICE_VIEW : 'reports_invoice.view',
  REPORT_EXHIBITION_VIEW : 'reports_exhibitions.view',
  REPORT_CHALLENGE_VIEW : 'reports_challenges.view',
  REPORT_TRANSACTION_VIEW : 'reports_transactions_and_orders.view',

  // recognition
  RECOGNITION_TYPE_CREATE : 'recognition.type.create',
  RECOGNITION_TYPE_VIEW : 'recognition.type.view',
  RECOGNITION_TYPE_UPDATE : 'recognition.type.update',
  
  //Bags
  BAG_VIEW : 'bag.view',
  BAG_CREATE : 'bag.create',
  BAG_UPDATE : 'bag.update',
  BAG_TRANSACTION_CREATE : 'bag.transaction.create',
  BAG_TRANSACTION_VIEW : 'bag.transaction.view',

  ///
  TEST_ERROR:'PERMISSIONS_ERROR',

  ORDERS_CANCEL:'orders.cancel',
  RESET_USERS:'users.app_users.reset_register',
}
